.popup {
  position: fixed;
  top: 0;
  left: 0;
  background-color: #102f54;
  z-index: 11;
  transition: opacity 0.5s;
  -webkit-clip-path: polygon(0 0, 100% 0, 100% 0, 0 0);
  clip-path: polygon(0 0, 100% 0, 100% 0, 0 0);
  transition: opacity 0.5s ease-in-out, -webkit-clip-path 0.5s ease-in-out;
  transition: clip-path 0.5s ease-in-out, opacity 0.5s ease-in-out;
  transition: clip-path 0.5s ease-in-out, opacity 0.5s ease-in-out,
    -webkit-clip-path 0.5s ease-in-out;
  opacity: 0;
}
.popup:after {
  content: "";
  background-image: url("https://triverseadvertising.com/rajgadh_estates_website/images/BackgroundLinesform.png");
  position: absolute;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  z-index: -1;
}

.popup.active {
  -webkit-clip-path: polygon(0 0, 100% 0, 100% 100%, 0 100%);
  clip-path: polygon(0 0, 100% 0, 100% 100%, 0 100%);
  opacity: 1;
}

.popup-content {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #fff;
  padding: 20px;
  border: 1px solid #ccc;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
  opacity: 0;
  transition: opacity 0.5s;
  z-index: 15;
}

.popup.active .popup-content {
  opacity: 1;
}

.close {
  position: absolute;
  top: 10px;
  right: 10px;
  z-index: 15;
}

.premixEnq {
  width: auto;
}
.premixEnq a {
  background-color: #11335a;
  writing-mode: vertical-rl;
  transform: rotate(0deg);
}
.premixEnq a span {
  color: #fff;
  padding: 20px 10px;
}
.premixEnq {
  top: 50%;
  transform: translateY(-50%);
}

.premixEnq a::before {
  background-color: #ed3237;
}

/* .popup h2 {
  width: fit-content;
}
.popup .formcont ul li:nth-child(4) {
  width: 60%;
  margin: 0 auto;
  }
  
.popup .formcont ul li input[type="text"],
.popup .formcont ul li input[type="email"],
.popup .formcont ul li input[type="tel"] {
  color: #fff;
  }
  
  .popup .formcont ul li input::placeholder {
    color: #fff;
    letter-spacing: 1.4px;
    text-transform: uppercase;
    font-size: 13px;
    }
    .popup .formcont ul {
      justify-content: space-around;
      } */

.premix_enq_from.enq_form {
  right: -100%;
  transition: ease 0.5s all;
  z-index: 15;
  position: fixed;
  margin-right: 0;
}

.premix_enq_from.enq_form.active {
  right: 0;
}
.premix_enq_from .formcont ul li {
  width: 100%;
  margin: 5px auto;
}
.premix_enq_from .contact_mobile_form {
  background-color: rgb(16 44 80);
  outline: #c81b1f solid 2px;
  padding: 5rem 3.4375rem;
}

.premix_enq_from .contact_mobile_form ul li input {
  background-color: #fff;
}

.close-btn {
  position: absolute;
  top: 20px;
  right: 25px;
  background: none;
  border: none;
  font-size: 24px;
  cursor: pointer;
  color: #fff;
}

.close-btn:hover {
  color: red;
}

.popUp_form {
  width: 100vw !important;
  max-width: 100vw;
}

.premixxxx_ {
  overflow: hidden;
  max-width: 100vw !important;
}
.premix_enq_from .contact_mobile_form .btn10 {
  background: #fff !important;
}
.premix_enq_from .contact_mobile_form h4 {
  color: #fff;
}
.popUp_form {
  position: relative;
  overflow: hidden;
}

.full-screen-thank-you {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.8);
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 9999;
  text-wrap: balance;
}

.full-screen-thank-you p {
  font-size: 2rem;
  line-height: 1.2;
  text-align: center;
}

@media (max-width: 780px) {
  .premix_enq_from.enq_form {
    right: inherit;
    left: -100%;
    transition: ease 0.5s all;
    z-index: 15;
  }
  .premix_enq_from.enq_form.active {
    left: 50%;
    right: inherit;
    transform: translate(-50%, -50%);
  }
}
